import React from "react"
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import styled from 'styled-components'

import Terms from './terms_and_conditions/zyppd-in-terms-and-conditions.pdf'
import tandc0 from './terms_and_conditions/tandc-0.png'
import tandc1 from './terms_and_conditions/tandc-1.png'
import tandc2 from './terms_and_conditions/tandc-2.png'
import tandc3 from './terms_and_conditions/tandc-3.png'
import tandc4 from './terms_and_conditions/tandc-4.png'
import tandc5 from './terms_and_conditions/tandc-5.png'
import tandc6 from './terms_and_conditions/tandc-6.png'
import tandc7 from './terms_and_conditions/tandc-7.png'
import tandc8 from './terms_and_conditions/tandc-8.png'
import tandc9 from './terms_and_conditions/tandc-9.png'
import tandc10 from './terms_and_conditions/tandc-10.png'
import tandc11 from './terms_and_conditions/tandc-10.png'
import tandc12 from './terms_and_conditions/tandc-10.png'
import tandc13 from './terms_and_conditions/tandc-10.png'
import tandc14 from './terms_and_conditions/tandc-10.png'
import tandc15 from './terms_and_conditions/tandc-10.png'

// ./legal/terms_and_conditions/tandc-0.png

export default function TermsAndConditions() {

    return (
        <Layout>
            <SEO
                title="Terms and Conditions"
                style="
                body: {
                    border: 2px solid red;
                }
                "
            />
            <Container>
                <PDFLink href={Terms} download>Download the zyppd.in terms and conditions</PDFLink>
                <LegalImgs>
                    <img src={tandc0} alt="" srcset="" />
                    <img src={tandc1} alt="" srcset="" />
                    <img src={tandc2} alt="" srcset="" />
                    <img src={tandc3} alt="" srcset="" />
                    <img src={tandc4} alt="" srcset="" />
                    <img src={tandc5} alt="" srcset="" />
                    <img src={tandc6} alt="" srcset="" />
                    <img src={tandc7} alt="" srcset="" />
                    <img src={tandc8} alt="" srcset="" />
                    <img src={tandc9} alt="" srcset="" />
                    <img src={tandc10} alt="" srcset="" />
                    <img src={tandc11} alt="" srcset="" />
                    <img src={tandc12} alt="" srcset="" />
                    <img src={tandc13} alt="" srcset="" />
                    <img src={tandc14} alt="" srcset="" />
                    <img src={tandc15} alt="" srcset="" />
                </LegalImgs>
            </Container>
        </Layout>
    )
}

const Container = styled.div`
    max-width: 900px;
    margin: 0 auto;
`
const PDFLink = styled.a`
    text-decoration: underline;
    text-align: center;
    margin: 2em 0;
    display: inline-block;
    width: 100%;
`

const LegalImgs = styled.div`
    display: grid;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    img {
        margin: 0 1em 1em 0;
        max-width: 550px;
        width: 100%;
        height: 100%; 
    }
`